import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import styles from "./styles.module.scss";

import { CreatorsSliderComponent } from "./slider";

export const CreatorsComponent = () => {
  const { t } = useTranslation();

  return (
    <section className={classNames(styles.section, styles.section__creators)}>
      <header
        className={classNames(styles.section__header, styles.creators__header)}
      >
        {t("creators__header")}
      </header>

      <div className={styles.creators__wrapper}>
        <CreatorsSliderComponent />
      </div>
    </section>
  );
};
