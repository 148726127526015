import age from "../../assets/images/requirements/age.svg";
import camera from "../../assets/images/requirements/camera.svg";
import play from "../../assets/images/requirements/play.svg";
import file from "../../assets/images/requirements/file.svg";
import connection from "../../assets/images/requirements/connection.svg";

export const REQUIREMENT_LIST_MUST = [
  {
    name: "age",
    text: "requirements__age",
    src: age,
  },
  {
    name: "file",
    text: "requirements__file",
    src: file,
  },
];

export const REQUIREMENT_LIST_CHANNELS = [
  {
    name: "play",
    text: "requirements__play",
    src: play,
  },
  {
    name: "views",
    text: "requirements__views",
    src: camera,
  },
  {
    name: "followers",
    text: "requirements__followers",
    src: connection,
  },
];
