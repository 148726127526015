import React, { FC } from "react";
import { Trans } from "react-i18next";
import classNames from "classnames";

import styles from "./styles.module.scss";

import { ExternalLinks } from "../../../navigation/external-links";
import { RequirementData } from "../types";

type Props = {
  requirement: RequirementData;
};

export const RequirementComponent: FC<Props> = ({ requirement }) => (
  <li key={requirement.name} className={styles.criteria__item}>
    <img
      src={requirement.src}
      alt={requirement.name}
      className={styles.criteria__img}
    />

    <div className={styles.item__text}>
      <Trans
        i18nKey={requirement.text}
        components={{
          strong: <strong />,
          codeOfConductLink: (
            <a
              className={classNames(styles.item__link, styles.link)}
              href={ExternalLinks.codeOfConduct}
              target="_blank"
              rel="noreferrer"
            >
              {/**/}
            </a>
          ),
        }}
      />
    </div>
  </li>
);
