import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";

import styles from "./styles.module.scss";

import { REQUIREMENT_LIST_MUST, REQUIREMENT_LIST_CHANNELS } from "./constants";
import { RequirementComponent } from "./requirement";

export const RequirementsComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <section className={classNames(styles.section, styles.requirements)}>
      <header
        className={classNames(
          styles.section__header,
          styles.requirements__header
        )}
      >
        {t("requirements__header")}
      </header>

      <div className={styles.requirements__content}>
        <div
          className={classNames(
            styles.section__description,
            styles.requirements__description
          )}
        >
          <p>
            <Trans
              i18nKey="requirements__text-1"
              components={{
                strong: <strong />,
              }}
            />
          </p>
          <p>{t("requirements__text-2")}</p>
        </div>

        <div className={styles.requirements__container}>
          <section className={styles.requirements__section}>
            <h3 className={styles.requirements__subtitle}>
              {t("requirements__must-title")}
            </h3>
            <ul className={styles.requirements__list}>
              {REQUIREMENT_LIST_MUST.map((requirement) => (
                <RequirementComponent
                  requirement={requirement}
                  key={requirement.name}
                />
              ))}
            </ul>
            <small />
          </section>

          <section className={styles.requirements__section}>
            <h3
              className={classNames(
                styles.requirements__subtitle,
                styles.requirements__channels
              )}
            >
              {t("requirements__channels-title")}
            </h3>
            <ul className={styles.requirements__list}>
              {REQUIREMENT_LIST_CHANNELS.map((requirement) => (
                <RequirementComponent
                  requirement={requirement}
                  key={requirement.name}
                />
              ))}
            </ul>
            <small className={styles.requirements__comment}>
              {t("requirements__channels-comment")}
            </small>
          </section>
        </div>
      </div>
    </section>
  );
};
