import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.scss";

import { ButtonLink } from "../../shared/button/button-link";
import { book } from "../../navigation/book";

export const FloatNavigationComponent: FC = () => {
  const { t } = useTranslation();

  return (
    <nav className={styles.floatNavigation}>
      <ButtonLink
        kind="primary"
        url={book.login}
        className={styles.floatNavigation__link}
      >
        {t("button__join")}
      </ButtonLink>
    </nav>
  );
};
