import React from "react";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";

import styles from "./styles.module.scss";

import { ExternalLinks } from "../../navigation/external-links";
import { AccordionComponent } from "../../shared/accordion";
import { FAQ } from "./constants";

export const FaqComponent = () => {
  const { t } = useTranslation();

  return (
    <section className={classNames(styles.section, styles.section__faq)}>
      <div className={styles.faq__content}>
        <header
          className={classNames(styles.section__header, styles.faq__header)}
        >
          {t("faq__header")}
        </header>

        <div>
          {FAQ.map((faq) => (
            <AccordionComponent
              key={faq.summary}
              summary={faq.summary}
              details={
                <Trans
                  i18nKey={faq.details}
                  components={{
                    div: <div />,
                    ul: <ul />,
                    li: <li />,
                    p: <p />,
                    codeOfConductLink: (
                      <a
                        target="_blank"
                        href={ExternalLinks.codeOfConduct}
                        rel="noreferrer"
                        className={styles.link}
                      >
                        {/**/}
                      </a>
                    ),
                    accountLink: (
                      <a
                        target="_blank"
                        href={ExternalLinks.ubiAccount}
                        rel="noreferrer"
                        className={styles.link}
                      >
                        {/**/}
                      </a>
                    ),
                  }}
                />
              }
            />
          ))}
        </div>
      </div>
    </section>
  );
};
